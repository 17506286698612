import * as React from "react";
import { LoadingTable, NoData, Section, TitleTable, WrapperDetailKpiPoint } from "layout";
import { Table } from "antd";
import clsx from "clsx";

export const ModalDetailKpiPoint = ({ data, dataKpiListConfig }) => {
    const resultFilter = React.useMemo(() => {
        const resultCommon = [],
            listFilter = [];
        if (data && dataKpiListConfig) {
            data.forEach((item) => {
                const tempItem = dataKpiListConfig.find((itemConfig) => Number(itemConfig.id) === Number(item.kpi_id));
                if (tempItem) {
                    listFilter.push({
                        ...item,
                        kpi_name: tempItem,
                    });
                }
            });
        }

        listFilter.forEach((item) => {
            const index = resultCommon.findIndex((itemCommon) => itemCommon.kpi_id === item.kpi_id);
            if (index === -1) {
                resultCommon.push({
                    kpi_id: item.kpi_id,
                    kpi_name: item.kpi_name,
                    kpi_score: item.kpi_score,
                    kpi_point: item.kpi_point,
                });
            } else {
                resultCommon[index].kpi_score += item.kpi_score;
                resultCommon[index].kpi_point += item.kpi_point;
            }
        });
        return resultCommon;
    }, [data, dataKpiListConfig]);

    if (!data) return <LoadingTable />;
    return (
        <div className="grid gap-4">
            {resultFilter && (
                <Section style={{ padding: "0" }} title="Total KPI score">
                    <Table
                        dataSource={resultFilter}
                        locale={{
                            emptyText: <NoData title="No data available yet" noData />,
                        }}
                        pagination={false}
                        columns={[
                            {
                                title: <TitleTable title="STT" />,
                                width: 50,
                                dataIndex: "id",
                                render: (_, record, index) => (
                                    <span className="block text-center font-semibold">{index + 1}</span>
                                ),
                            },
                            {
                                title: <TitleTable title="KPI" />,
                                dataIndex: "full",
                                render: (_, record) => (
                                    <div className="flex gap-1">
                                        <p className="text-left">{record.kpi_id}</p>
                                        <span>::</span>
                                        <p className="text-left">{record.kpi_name.name}</p>
                                    </div>
                                ),
                            },
                            {
                                title: <TitleTable title="Total score" />,
                                dataIndex: "kpi_score",
                                render: (text) => (
                                    <p className={clsx("text-center", text > 0 ? "text-[#03942c]" : "text-[#dc3545]")}>
                                        {text}
                                    </p>
                                ),
                            },
                        ]}
                    />
                </Section>
            )}
            <Section style={{ padding: "0" }} title="Detailed KPI by day">
                <WrapperDetailKpiPoint data={data} dataKpiListConfig={dataKpiListConfig} />
            </Section>
        </div>
    );
};
